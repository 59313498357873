.heading{
    font-size: 48px;
    padding-top: 120px;
    color: #fff;
    margin: auto;
    font-weight: bold;
    text-transform: capitalize;
}

.sectors{
    color: #ffffff;
    margin-top: 3px;
}

.sub_text{
    font-size: 20px;
    color: #ffffff;
    margin-top: 1rem;
    margin-bottom: 20px;
    padding: 0px 1rem;
}
.iconState{
    gap: 70px;
}
.imageIcon{
    width: 55px;
}
@media screen and (max-width: 1000px) {
  .iconState{
        flex-wrap: wrap;
    }
}
@media screen and (max-width: 770px) {
    .heading{
        font-size: 25px;
    }
    .sub_text{
        font-size: 17px;
    }
}
