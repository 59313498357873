.box{
    box-shadow: 2px 3px 10px 5px rgba(255, 255, 255, 0.2);
    padding-top: 75px;
    padding-bottom: 60px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 24px;
    height: 400px;
    border-radius: 10px;
}
.box:hover{
    box-shadow: rgb(17,217,197) 0px 2px 4px 0px, rgb(17,217,197) 0px 2px 16px 0px;
    transform: scale(1.02);
    transition: .5s ease;
    background-image: radial-gradient(circle at 0 0, #11d9c5, #292929 150px);
    border-bottom: 5px solid #11d9c5;
}
.heading{
    font-size: 24px;
    color: #11d9c5;
    font-weight: bold;
    margin-top: 20px;
}
.text{
    font-size: 18px;
    color: #FFF;
    /* width: 80%; */
    margin-left: auto;
    margin-right: auto;
}
.to{
    cursor: pointer;
    top: 00px;
}
.to:hover{
    transform: scale(1.1);
}


@media screen and (max-width: 1000px) {
    .heading{
        font-size: 20px;
    }
    .text{
        font-size: 16px;
    }
    
}
@media screen and (max-width: 992px) {
    .box{
        height: 400px;
    }
}

@media screen and (max-width: 768px) {
    .box{
        padding-right: 20px;
        padding-left: 20px;
        height: 350px;
    }
}

@media screen and (max-width: 410px) {
    .heading{
        font-size: 18px;
    }
    .text{
        font-size: 14px;
    }
}