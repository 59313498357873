.contain{
    margin-top: 130px;
    margin-bottom: 130px;
    padding-left: 60px !important;
    padding-right: 60px !important;
}

.heading{
    font-size: 30px;
    color: #11d9c5;
    font-weight: 500;
}
.subHeading{
    font-size: 34px;
    font-weight: bold;
    color: #f4f2f2;
    width: 645px;
}

@media screen and (max-width: 1200px) {
    .contain{
        margin-top: 80px;
        margin-bottom: 80px;
        padding-left: 40px !important;
        padding-right: 40px !important;
    }
}

@media screen and (max-width: 770px) {
    .subHeading{
        font-size: 25px;
        width: auto;
    }
    .heading{
        font-size: 20px;
    }
}

@media screen and (max-width: 430px) {
    .subHeading{
        font-size: 20px;
    }
    .heading{
        font-size: 17px;
    }
    .contain{
        margin-top: 80px;
        margin-bottom: 100px;
    }
}
@media screen and (max-width: 380px) {
    .contain{
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}