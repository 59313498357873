.heading{
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 500;
    color: #11d9c5;
    margin-top: 77px;
    margin-bottom: 0px;
}
.subHeading{
    font-family: 'Poppins';
    font-size: 34px;
    font-weight: bold;
    color: #fffffc;
    margin-top: 36px;
}
.content{
    font-family: 'Poppins';
    font-size: 14px;
    color: #ffffff;
    margin-top: 14px;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;
}
.about{
    font-family: 'Poppins';
    font-size: 50px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 4px;
    margin-top: 50px;
    border-radius: 10px;
}

.img1{
    width: 100%;
    height: auto;
    border-radius: 10px;
}
.img2{
    text-align: left;
    padding-left: 100px;
    margin-top: 14px;
    width: 100%;
    height: auto;
    margin-bottom: 82px;
}
.creativity{
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: bold;
    color: #11d9c5;
    text-align: left;
}
.contn{
    font-family: 'Poppins';
    font-size: 14px;
    color: #ffffff;
    text-align: left;
    text-align: justify;
}

@media screen and (max-width: 992px) {
    .heading{
        font-size: 25px;
        margin-top: 60px;
    }
    .subHeading{
        font-size: 29px;
        margin-top: 30px;
    }
    .img2{
        padding-left: 0px;
        margin-bottom: 60px;
    }
    .about{
        font-size: 40px;
    }
    .creativity{
        font-size: 25px;
    }

}
@media screen and (max-width: 768px) {
    .content{
        width: 100%;
    }
    .about{
        margin-top: 80px;
    }
    .imgContain{
        margin-left: 0px;
    }
}

@media screen and (max-width: 650px) {
    .heading{
        font-size: 20px;
        margin-top: 40px;
    }
    .subHeading{
        font-size: 24px;
        margin-top: 20px;
    }
    .img2{
        margin-bottom: 40px;
    }
    .about{
        font-size: 30px;
        margin-top: 60px;
    }
    .creativity{
        font-size: 20px;
    }

}