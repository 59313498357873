.contain{
    padding-bottom: 130px;
}
.row{
    margin-top: 130px !important;
}
.row2{
    margin-top: 130px !important;
}
.head{
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: bold;
    color: #11d9c5;
    text-align: left;
    margin-top: 135px;
}
.content{
    font-family: 'Poppins';
    font-size: 14px;
    color: #ffffff;
    opacity: .8;
    text-align: left;
    text-align: justify;
}
.img{
    width: 100%;
    height: auto;
    border-radius: 10px;
}
.head1{
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: bold;
    color: #11d9c5;
    text-align: left;
    margin-top: 135px;
}
.content1{
    font-family: 'Poppins';
    font-size: 14px;
    color: #ffffff;
    opacity: .8;
    text-align: left;
    margin-right: 100px;
    text-align: justify;
}
.img1{
    width: 100%;
    height: auto;
    border-radius: 10px;
}

@media screen and (max-width: 992px) {
    .head{
        margin-left: 0px;
        margin-top: 90px;
    }
    .head1{
        margin-top: 90px;
    }
    .content1{
        margin-right: 0px;
    }
    .row{
        margin-top: 80px !important;
    }
    .contain{
        padding-bottom: 80px;
    }
    
}
@media screen and (max-width: 768px) {
    .head, .head1{
        margin-top: 50px;
    }
}
@media screen and (max-width: 768px) {
    .head, .head1{
        font-size: 22px;
        margin-top: 30px;
    }
    .row{
        margin-top: 50px !important;
    }
}