.nav_text{
    font-size: 16px;
    color: #fff !important;
    width: 120px;
}
.nav_text:hover{
    padding-top: 6px;
    font-size: 18px;
    color: #11d9c5 !important;
    font-weight: bold;
}
.active{
    padding-top: 6px !important;
    font-size: 18px;
    color: #11d9c5 !important;
    font-weight: bold;
}

.drop{
    width: 120px;
}

.drop:hover .nav_text{
    color: #11d9c5 !important;
    font-weight: bold;
    cursor: pointer;
    font-size: 18px;
}
.logo{
    width: 130px !important;
}
.dropdownText{
    text-decoration: none;
    color: #ffffff;
}

.dropdownItem:hover{
    background-color: #011126 !important;
}
.dropdownItem:hover .dropdownText{
    color: #f6f7f7;
}
.drop:hover{
    background-color: #053c84;
    border-radius: 10px;
}

/* NavbarMain.module.css */
.customToggle {
    display: none;
    background: none;
    border: 2px solid white;
    cursor: pointer;
}

.toggleIcon {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  background-color: white;
  transition: all 0.3s ease-in-out;
}

/* Additional styles for when the navbar is expanded */
.customToggle.active .toggleIcon:nth-child(2) {
  opacity: 0;
}

.customToggle.active .toggleIcon:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.customToggle.active .toggleIcon:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

@media screen and (max-width: 991px) {
    .customToggle {
        display: block;
    }
    .dropdownItem{
        text-align: center !important;
    }
    .nav_text{
        width: auto;
        padding-top: 0px;
    }
    .drop{
        width: auto;
    }
    .logo {
        width: 100px !important;
    }
}

@media screen and (max-width: 600px) {
    .logo{
        width: 100px !important;
    }
}
