.contain {
  margin-top: 130px;
  margin-bottom: 130px;
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.heading {
  font-size: 30px;
  color: #11d9c5;
  font-weight: 500;
}
.subHeading {
  font-size: 34px;
  font-weight: bold;
  color: #11d9c5;
  width: 645px;
}

@media screen and (max-width: 1200px) {
  .contain {
    margin-top: 80px;
    margin-bottom: 80px;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

@media screen and (max-width: 770px) {
  .subHeading {
    font-size: 25px;
    width: auto;
  }
  .heading {
    font-size: 20px;
  }
}

@media screen and (max-width: 430px) {
  .subHeading {
    font-size: 20px;
  }
  .heading {
    font-size: 17px;
  }
  .contain {
    margin-top: 80px;
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 380px) {
  .contain {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
/* // Team Home by Id */
.box {
  background-color: #011126;
  box-shadow: 2px 3px 10px 5px #11d9c5;
  padding: 10px;
  margin-top: 24px;
  height: 450px;
  border-radius: 10px;
  filter: grayscale(100%);
  transition: filter 0.5s ease;
  position: relative; /* Add position relative for absolute positioning of elements inside */
}

.box:hover {
  box-shadow: #11d9c5 0px 2px 4px 0px,
   #11d9c5 0px 2px 16px 0px;
  transform: scale(1.02);
  filter: grayscale(0%);
}

.img {
  width: 300px;
  transition: transform 0.5s ease-out; /* Add transition for smooth zoom animation */
  border-radius: 10px;
}

.img:hover {
  transform: scale(0.9); /* Zoom out the image on hover */
}

.heading {
  font-size: 24px;
  color: #11d9c5;
  font-weight: bold;
  margin-top: 7px;
  margin-bottom: 0px !important;
}

.text {
  font-size: 14px;
  color: #01060c;
  margin-left: auto;
  margin-right: auto;
}
.iconColor{
  color:#11d9c5;
  width: 25px;
  height: 25px;
}
.iconColor:hover{
  color:#38a5f3;
}
.to {
  cursor: pointer;
  top: 0px;
}

.to:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 1000px) {
  .heading {
    font-size: 20px;
  }
  .text {
    font-size: 16px;
  }
  .img {
    width: 270px;
  }
}

@media screen and (max-width: 992px) {
  .box {
    height: 450px;
  }
}

@media screen and (max-width: 768px) {
  .box {
    padding-right: 20px;
    padding-left: 20px;
    height: 400px;
  }
  .img {
    width: 250px;
  }
}

@media screen and (max-width: 410px) {
  .heading {
    font-size: 18px;
  }
  .text {
    font-size: 14px;
  }
}
