.contain{
    margin-top: 125px;
    margin-bottom: 125px;
}
.headingMain{
    font-size: 40px;
    font-family: 'Poppins';
    color: #11d9c5;
    margin-bottom: 0px;
}
.heading{
    font-size: 40px;
    font-family: 'Poppins';
    color: #ffffff;
    margin-bottom: 0px;
}
.heading_content{
    font-size: 18px;
    font-family: 'Poppins';
    color: #ffffff;
    opacity: 0.9;
    margin-bottom: 10px;
}
.contentImg{
    width: 80%;
    border-radius: 10px;
}
.subHead{
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
}
.justify{
    text-align:justify;
}

@media screen and (max-width:1200px){
    .contentImg{
        width: 90%;
    }
    .heading{
        font-size: 30px;
    }
    .subHead{
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .heading_content{
        font-size: 16px;
    }
}

@media screen and (max-width: 992px){
    .contentImg{
        width: 100%;
    }
    .heading{
        font-size: 25px;
    }
    .subHead{
        margin-top: 0px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 768px){
    .contentImg{
 
    }

}