.statsContainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 50px;
    color: #fff;
    padding: 20px;
}
.statItem {
    text-align: center;
    margin: 10px;
    width: 300px;
}
.statItem h1 {
    margin: 0;
    font-size: 2em; /* Adjust the size as needed */
}
.statItem p {
    margin: 5px 0 0;
    font-size: 0.8em; /* Adjust the size as needed */
}
@media screen and (max-width: 1200px) {
    .statsContainer{
        flex-wrap: wrap;
    }
}